import React, { createRef } from "react";
import { Col, Row, Spinner, UncontrolledTooltip } from "reactstrap";
import { getExtentionFile, isImageFile } from "../helpers/utility";
import Dropzone from "react-dropzone";

function UploadFile({
    id,
    disableEdit,
    handleAcceptedFiles,
    typeFile,
    file,
    titleDocument,
    showPreviewImage,
    processUpload,
}) {
    const dropzoneRef = createRef();
    const openDialog = () => {
        if (dropzoneRef.current) {
            dropzoneRef.current.open();
        }
    };

    const FileIcon = ({ file }) => {
        if (!file) {
            return <i className="uil-folder-upload font-size-20" />;
        }

        return isImageFile(file) ? (
            <i className="uil-scenery font-size-20" />
        ) : (
            <i className="uil-file-alt font-size-20" />
        );
    };

    return (
        <Dropzone
            ref={dropzoneRef}
            noClick
            noKeyboard
            onDrop={(acceptedFiles) => handleAcceptedFiles(acceptedFiles, typeFile)}
        >
            {({ getRootProps, getInputProps }) => (
                <div
                    {...getRootProps({ className: "dropzone" })}
                    style={{ backgroundColor: disableEdit ? "#f4f4f4" : "#ffffff" }}
                    id={id}
                >
                    <input
                        {...getInputProps()}
                        id={titleDocument.toLowerCase().split(" ").join("-").replace(/[/()]/g, "")}
                    />
                    <div className="p-1 px-2">
                        <Row className="align-items-center">
                            <Col className="col-auto">
                                <div className="avatar-sm font-weight-bold">
                                    <span className="avatar-title rounded bg-soft-primary text-primary">
                                        <FileIcon file={file} />
                                    </span>
                                </div>
                            </Col>
                            <Col className="pl-0">
                                <span className="text-muted font-weight-bold">{titleDocument}</span>
                                <p className="mb-0">
                                    format file: {file ? getExtentionFile(file) : "-"}
                                </p>
                                {(typeFile == "ktp" ||
                                    typeFile == "selfie_ktp" ||
                                    typeFile === "ro_ktp" ||
                                    typeFile == "ro_selfie_ktp") && (
                                    <>
                                        <ul className="m-0 pl-3">
                                            <li>
                                                <small className="mb-0">{`Format foto harus berupa JPG/JPEG`}</small>
                                            </li>
                                            <li>
                                                <small className="mb-0">{`Ukuran maksimal foto 1 MB`}</small>
                                            </li>
                                        </ul>
                                    </>
                                )}
                                {(typeFile == "coe" || typeFile == "selfie_contract_document") && (
                                    <ul className="m-0 pl-3">
                                        <li>
                                            <small className="mb-0">{`Format dokumen harus berupa PDF`}</small>
                                        </li>
                                    </ul>
                                )}
                            </Col>
                            <Col className="col-auto row d-flex justify-content-end mr-1">
                                {processUpload === typeFile && (
                                    <Spinner color={"#369378"} size="sm" className="mr-2" />
                                )}
                                {file && !processUpload && isImageFile(file) && (
                                    <div>
                                        <a
                                            className="btn btn-link text-primary btn-lg p-0 ml-1"
                                            id={`btn-d-${id}`}
                                            onClick={showPreviewImage}
                                        >
                                            <i className="uil uil-eye font-size-20"></i>
                                        </a>
                                        <UncontrolledTooltip
                                            placement="bottom"
                                            target={`btn-d-${id}`}
                                        >
                                            Lihat Dokumen
                                        </UncontrolledTooltip>
                                    </div>
                                )}
                                {file && !processUpload && !isImageFile(file) && (
                                    <div>
                                        <a
                                            href={file}
                                            target="_blank"
                                            className="btn btn-link text-primary btn-lg p-0 ml-1"
                                            id={`btn-d-${id}`}
                                        >
                                            <i className="uil uil-eye font-size-20"></i>
                                        </a>
                                        <UncontrolledTooltip
                                            placement="bottom"
                                            target={`btn-d-${id}`}
                                        >
                                            Lihat Dokumen
                                        </UncontrolledTooltip>
                                    </div>
                                )}
                                {!processUpload && !disableEdit && (
                                    <div>
                                        <a
                                            id={`btn-dl-${id}`}
                                            className="btn btn-link text-primary btn-lg p-0 ml-1"
                                            onClick={openDialog}
                                        >
                                            <i className="uil uil-upload-alt font-size-20"></i>
                                        </a>
                                        <UncontrolledTooltip
                                            placement="bottom"
                                            target={`btn-dl-${id}`}
                                        >
                                            Unggah Dokumen
                                        </UncontrolledTooltip>
                                    </div>
                                )}
                            </Col>
                        </Row>
                    </div>
                </div>
            )}
        </Dropzone>
    );
}

export default UploadFile;
